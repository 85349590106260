import moment from 'moment'
import DateRangePicker from 'daterangepicker/daterangepicker'

class DatePicker {
  static async init () {
    $.fn.daterangepicker = function (options, callback) {
      var implementOptions = $.extend(true, {}, $.fn.daterangepicker.defaultOptions, options)
      this.each(function () {
        var el = $(this)
        if (el.data('daterangepicker')) { el.data('daterangepicker').remove() }
        el.data('daterangepicker', new DateRangePicker(el, implementOptions, callback))
      })
      return this
    }

    const dateSettings = this.localeSettings('date')
    const timeSettings = this.localeSettings('time')

    $('.datepicker-single').each((_index, item) => {
      $(item).daterangepicker({
        singleDatePicker: true,
        timePickerIncrement: 30,
        locale: dateSettings
      })
    })

    $('.datepicker-range').each((_index, item) => {
      $(item).daterangepicker({
        timePickerIncrement: 30,
        locale: dateSettings
      })
    })

    $('.datetimepicker-single').each((_index, item) => {
      $(item).daterangepicker({
        timePicker: true,
        singleDatePicker: true,
        timePickerIncrement: 30,
        locale: timeSettings
      })
    })

    $('.datetimepicker-range').each((_index, item) => {
      $(item).daterangepicker({
        timePicker: true,
        timePickerIncrement: 30,
        locale: timeSettings
      })
    })
  }

  static localeSettings (type) {
    moment.locale(I18n.lang)

    let result = {
      'format': 'MM/DD/YYYY',
      'separator': ' - ',
      'applyLabel': I18n.t('accept'),
      'cancelLabel': I18n.t('decline'),
      'daysOfWeek': moment.weekdaysMin(),
      'monthNames': moment.months(),
      'firstDay': moment().startOf('week').day()
    }

    if (type === 'time') result.format = 'MM/DD/YYYY hh:mm a'

    return result
  }
}

export default DatePicker
